import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {DentistModel} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';

export const useOnboardingCheckout = (options?: UseOnboardingCheckoutProps) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.onboardingCheckout),
		mutationFn: d => coreApiClient.patch(endpoints.cart.my.onboardingCheckout, d),
		...options,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			await queryClient.invalidateQueries(keysResolver(endpoints.orders.list));
			options?.onSuccess?.(data, variables, context);
		},
	});
};

type UseOnboardingCheckoutProps = UseMutationOptions<
	AxiosResponse<CartModel>,
	AxiosError<HydraError>,
	OnboardingCheckoutOrderRequest
>;

interface OnboardingCheckoutOrderRequest {
	shippingAddress: Iri;
	shippingMethod: Iri;
	billingAddress: Iri;
	paymentMethodType: 'card' | 'invoice';
	cardId?: string;
	option?: string;
	scan?: Iri;
	dentistNotes?: DentistModel | null;
}