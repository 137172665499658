import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import {CollectionParams, HydraListResponse} from '@mgp-fe/shared/core-api/domain/base.ts';
import {AxiosResponse} from 'axios';
import {SettingModel} from '@mgp-fe/shared/core-api/domain/setting.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';

export default function useSettingsListQuery({params, options}: SettingsListQueryProps) {
	return useQuery({
		queryKey: keysResolver(endpoints.settings.index, 'get', params),
		queryFn: () => coreApiClient.get(endpoints.settings.index, {params}),
		...options,
	});
}

interface SettingsListQueryProps {
	params?: CollectionParams;
	options?: UseQueryOptions<AxiosResponse<HydraListResponse<SettingModel>>>;
}