import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import {CartModel} from '@mgp-fe/shared/core-api/domain/cart.ts';
import {AxiosError, AxiosResponse} from 'axios';
import {HydraError, Iri} from '@mgp-fe/shared/core-api/domain/base.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {z} from 'zod';
import {phoneNumberSchema} from '@mgp-fe/shared/core-api/schemas/phone-number.ts';

export default function useCartCheckoutCompleteMutation(props?: UseApplyVoucherMutationProps) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: keysResolver(endpoints.cart.my.complete),
		mutationFn: (rq) => coreApiClient.patch<CartModel>(endpoints.cart.my.complete, rq),
		...props,
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries(keysResolver(endpoints.cart.my.item));
			await queryClient.invalidateQueries(keysResolver(endpoints.orders.list));
			props?.onSuccess?.(data, variables, context);
		},
	});
}

type UseApplyVoucherMutationProps = UseMutationOptions<
	AxiosResponse<CartModel>,
	AxiosError<HydraError>,
	CartCheckoutCompleteRequest
>;

export interface CartCheckoutCompleteRequest {
	option?: CheckoutCompleteOption;
	scan?: Iri | null | undefined;
	dateOfScan?: string | null;
	dentistNotes?: DentistModel | null;
}

export const DentistNoteRequestSchema = z.object({
	officeName: z.string().min(1, 'Please enter the office name'),
	dentistName: z.string().min(1, 'Please enter the dentist name'),
	phone: phoneNumberSchema.refine(
		v => !!v,
		{ message: 'Phone number is required' }
	),
	email: z.union([
		z.string().email('Invalid email'),
		z.literal(''),
	]).nullable().optional(),
});

export type CheckoutCompleteOption = 'existing_scan' | 'new_3d_scan' | 'new_impression_kit_scan' | 'new_dentist';

export interface DentistModel {
	officeName: string | null;
	dentistName: string;
	phone?: string | null | undefined;
	email: string | null;
}