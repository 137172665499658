import {OrderModel} from '@mgp-fe/shared/core-api/domain/order.ts';
import {
	CartCheckoutCompleteRequest,
	CheckoutCompleteOption, DentistModel,
} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';
import useScansListQuery from '@mgp-fe/shared/core-api/queries/scan/list.ts';
import React, {useEffect, useMemo, useState} from 'react';
import {humanizeEnumValue} from '@mgp-fe/shared/utils/humanize-string.ts';
import DateFormatted from '@mgp-fe/shared/modules/formatters/DateFormatted.tsx';
import {Tabs, TabsContent, TabsList, TabsTrigger} from '@mgp-fe/shared/ui/tabs.tsx';
import useSettingsListQuery from '@mgp-fe/shared/core-api/queries/setting/list.ts';
import DentistNoteForm from '@mgp-fe/shared/modules/shop/components/checkout/dentist-note-form.tsx';
import {cn} from '@mgp-fe/shared/utils';


export default function OrderScanOption({order, changeHandler, option}: {
	order?: OrderModel,
	changeHandler: (k: CartCheckoutCompleteRequest[keyof CartCheckoutCompleteRequest], v: CartCheckoutCompleteRequest[keyof CartCheckoutCompleteRequest]) => void,
	option: CheckoutCompleteOption,
}) {
	const [impressionKitEnabled, setImpressionKitEnabled] = useState<boolean>(false);
	const [dentistNote, setDentistNote] = useState<DentistModel | undefined>(undefined);

	const settingsQuery = useSettingsListQuery({});
	const scansQuery = useScansListQuery({
		params: {state: ['requested', 'waiting', 'synced'], itemsPerPage: 1},
	});
	const latestScan = useMemo(
		() => scansQuery.data?.data['hydra:member'][0],
		[scansQuery.data?.data],
	);


	useEffect(() => {
		if (option !== 'new_dentist') {
			setDentistNote(undefined);
			changeHandler('dentistNotes', undefined);
			return;
		}
	}, [option]);
	const handleDentistNoteChange = (newValues: DentistModel) => {
		setDentistNote((prev) => {
			if (JSON.stringify(prev) === JSON.stringify(newValues)) {
				return prev;
			} else {
				return newValues;
			}
		});
	};

	useEffect(() => {
		if (settingsQuery.data?.data['hydra:member']) {
			setImpressionKitEnabled(settingsQuery.data?.data['hydra:member'].find(s => s.key === 'impression_kits_enabled')?.boolValue === true);
		}
	}, [settingsQuery.data?.data]);

	useEffect(() => {
		if (!scansQuery.data) return;
		changeHandler('option', dentistNote ? 'new_dentist' : latestScan ? 'existing_scan' : impressionKitEnabled ? 'new_impression_kit_scan' : 'new_dentist');
		dentistNote && changeHandler('dentistNotes', dentistNote);
		latestScan && changeHandler('scan', latestScan['@id']);
		dentistNote && changeHandler('scan', undefined);
	}, [scansQuery.data, impressionKitEnabled, dentistNote, latestScan]);


	const scanType = latestScan?.provider === 'impression_kit'
		? 'impression'
		: `${humanizeEnumValue(latestScan?.provider || '')} scan`;
	const scanDate = latestScan?.dateOfScan
		? <>&nbsp;from your order on <DateFormatted date={latestScan.dateOfScan} showDateOnly/>&nbsp;</> : '';
	return <div
		className={cn(order?.orderItems.filter(oi => !!oi.mouthguard).length === 0 ? 'invisible' : '', 'md:overflow-x-auto')}>

		<h5 className='mb-small font-normal'>Select a scan for the mouthguard in your cart:</h5>

		<Tabs
			value={option}
			onValueChange={v => changeHandler('option', v as CheckoutCompleteOption)}
			defaultValue={impressionKitEnabled ? 'new_impression_kit_scan' : 'existing_scan'}>
			<TabsList
				className='flex flex-wrap md:flex-nowrap items-start justify-start md:overflow-x-auto w-full '
			>
				{impressionKitEnabled ? <TabsTrigger
					value='new_impression_kit_scan'
					disabled={!impressionKitEnabled}
				>
					{impressionKitEnabled ? 'Get new impression kit' : '(Impression kits out of stock)'}
				</TabsTrigger> : ''}
				{latestScan !== undefined ? <TabsTrigger
					value='existing_scan'
					disabled={!latestScan}
				>
					{latestScan
						? `Use Existing ${
							latestScan.provider === 'impression_kit' ? 'impression' : 'scan'
						}`
						: '(No scan / impression available)'}
				</TabsTrigger> : ''}
				<TabsTrigger
					value='new_dentist'
				>
					External scan
				</TabsTrigger>
			</TabsList>
			{impressionKitEnabled ? <TabsContent value='new_impression_kit_scan'>
				<p>You will receive an impression kit with instructions on how to take your dental impression at home.
					Once we receive the impression kit back we will proceed with production.</p>
			</TabsContent> : ''}
			{latestScan !== undefined ? <TabsContent value='existing_scan'>
				<p>We will use your <strong
					className='text-primary'>{scanType} {scanDate} - {latestScan?.provider === 'external' &&
					<span>{latestScan?.dentistNotes.officeName}, {latestScan?.dentistNotes.dentistName} </span>}</strong>
					for production of your new mouthguard.</p>

				{latestScan?.state !== 'synced' ? <p className='text-primary'>
					<strong>Note:</strong> We did not receive your <strong>{scanType}</strong> yet.&nbsp;
					{scanType === 'impression' ? 'Check your mailbox.'
						: 'You can contact your doctor if they already sent it to us.'}
				</p> : ''}
			</TabsContent> : ''}
			<TabsContent className=' flex flex-col gap-2.5' value='new_dentist'>
				<p>You can provide information about your dentist who performed your teeth scan for the mouthguard.
					We will reach out to your dentist directly to gather any additional details needed.</p>
				<DentistNoteForm
					onChange={handleDentistNoteChange}
				/>
			</TabsContent>
		</Tabs>
	</div>;
}