import {OrderState} from '@mgp-fe/shared/core-api/domain/order.ts';
import {badgeVariants} from '@mgp-fe/shared/ui/badge.tsx';
import {PaymentState} from '@mgp-fe/shared/core-api/domain/payment.ts';

export const OrderStateBadgeVariantMap = {
	cart: 'outline',
	'scan_missing': 'outline',
	order: 'warning',
	processing: 'warning',
	completed: 'success',
	shipped: 'success',
	'partially_shipped': 'success',
	delivered: 'default',
	returned: 'destructive',
	canceled: 'destructive',
} as Record<OrderState, keyof typeof badgeVariants>;

export const PaymentStateBadgeVariantMap = {
	cart: 'outline',
	succeeded: 'default',
	pending: 'warning',
	failed: 'destructive',
	refunded: 'outline',
	'partially_refunded': 'outline',
} as Record<PaymentState, keyof typeof badgeVariants>;

export const OrderStateLabelMap = {
	cart: 'Cart (not ordered yet)',
	'scan_missing': 'Missing scan',
	order: 'Created',
	processing: 'Working on it',
	completed: 'Waiting for shipment',
	shipped: 'Shipped',
	'partially_shipped': 'Partially Shipped',
	delivered: 'Delivered',
	returned: 'Returned',
	canceled: 'Canceled',
	refunded: 'Refunded',
} as Record<OrderState, string>;