import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import CartContentTable from '@mgp-fe/shared/modules/shop/components/CartContentTable.tsx';
import AddressFormatted from '@mgp-fe/shared/modules/formatters/AddressFormatted.tsx';
import useCartMyQuery from '@mgp-fe/shared/core-api/queries/cart/my.tsx';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import useAuth from '@mgp-fe/shared/modules/auth/hooks/useAuth.ts';
import {Button} from '@mgp-fe/shared/ui/button.tsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from '@mgp-fe/shared/ui/Modal.tsx';
import AddStripeCardForm from '@mgp-fe/shared/modules/account/components/payment-methods/AddStripeCardForm.tsx';
import useToggle from '@mgp-fe/shared/hooks/useToggle.ts';
import useStripeMyCardsListQuery from '@mgp-fe/shared/core-api/queries/stripe/my-cards.ts';
import SmallPaymentMethodCard, {
	SmallPaymentMethodCardSkeleton,
} from '@mgp-fe/shared/modules/shop/components/checkout/SmallPaymentMethodCard.tsx';
import {useOnboardingCheckout} from '@mgp-fe/shared/core-api/mutations/cart/onboarding-checkout.ts';
import {minBy} from 'lodash';
import useNotify from '@mgp-fe/shared/ui/notifications/use-notify.ts';
import useCartMyShippingMethodsListQuery from '@mgp-fe/shared/core-api/queries/cart/my-shipping-methods.ts';
import {ShippingMethodCollectionModel} from '@mgp-fe/shared/core-api/domain/shipping/method.ts';
import OrderScanOption from '@mgp-fe/shared/modules/shop/components/checkout/OrderScanOption.tsx';
import {
	CartCheckoutCompleteRequest,
	CheckoutCompleteOption, DentistModel, DentistNoteRequestSchema,
} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';
import {useMap} from 'usehooks-ts';
import ClaimVoucherForm from '@mgp-fe/shared/modules/vouchers/components/ClaimVoucherForm.tsx';
import OrderAmountSummaryCard, {
	OrderSummaryLoadingSkeleton,
} from '@mgp-fe/shared/modules/orders/components/order-detail/OrderAmountSummaryCard.tsx';
import useVouchersListQuery from '@mgp-fe/shared/core-api/queries/voucher/list.ts';
import useApplyVoucherMutation from '@mgp-fe/shared/core-api/mutations/cart/apply-voucher.ts';
import {Dialog, Transition} from '@headlessui/react';
import ProductsList from '@mgp-fe/shared/modules/shop/components/ProductsList.tsx';
import {Alert, AlertDescription, AlertTitle} from '@mgp-fe/shared/ui/alert.tsx';
import MoneyFormatted from '@mgp-fe/shared/modules/formatters/MoneyFormatted.tsx';
import {OnboardingContext} from '@mgp-fe/shared/modules/onboarding';
import useOnboardingOrderSetupMutation from '@mgp-fe/shared/core-api/mutations/user/customer/onboarding-order-setup.ts';
import RedeemCouponForm from '@mgp-fe/shared/modules/promotions/RedeemCouponForm.tsx';
import AppliedCoupons from '@mgp-fe/shared/modules/promotions/AppliedCoupons.tsx';
import useProductsListQuery from '@mgp-fe/shared/core-api/queries/product/list.ts';
import AvailablePromotions from '@mgp-fe/shared/modules/promotions/AvailablePromotions.tsx';
import AddPhoneForm from '@mgp-fe/shared/modules/account/components/phone-number/AddPhoneForm.tsx';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';

export default function OrderIndexPage() {
	const onboardingContext = useContext(OnboardingContext);
	const {user} = useAuth<Customer>();
	const {notifySuccess, notifyError} = useNotify();
	const checkoutMutation = useOnboardingCheckout({
		onSuccess: () => onboardingContext.setOnboardingStep('completed'),
		onError: error => {
			alert(error.response?.data['hydra:description'] || 'Error when finishing checkout, please check payment');
		},
	});
	const onboardingOrderMutation = useOnboardingOrderSetupMutation();
	const applyVoucherMutation = useApplyVoucherMutation({
		onSuccess: () => notifySuccess({message: 'Voucher applied.'}),
	});
	const shippingMethodsQuery = useCartMyShippingMethodsListQuery();
	const myCartQuery = useCartMyQuery();
	const order = useMemo(() => myCartQuery.data?.data.order, [myCartQuery.data?.data.order]);
	const myCardsQuery = useStripeMyCardsListQuery({
		options: {keepPreviousData: true},
	});

	const form = useForm<z.infer<typeof DentistNoteRequestSchema>>({
		resolver: zodResolver(DentistNoteRequestSchema),
		mode: 'onChange',
		defaultValues: {
			officeName: undefined,
			dentistName: undefined,
			phone: undefined,
			email: undefined,
		},
	});
	const availableVouchers = useVouchersListQuery({
		params: {exists: {orderItem: false, usedAt: false}},
	});

	const productsQuery = useProductsListQuery({
		params: {
			'channels.name': 'customer',
		},
	});

	const [map, actions] = useMap<
		keyof CartCheckoutCompleteRequest,
		CartCheckoutCompleteRequest[keyof CartCheckoutCompleteRequest]
	>([
		['option', undefined],
		['scan', undefined],
		['dateOfScan', undefined],
		['dentistNotes', undefined],
	]);
	const [paymentMethod, setPaymentMethod] = useState<string | undefined>(undefined);
	const [appliedAvailableVoucher, setAppliedAvailableVoucher] = useState(false);
	const displayProductsListModal = useToggle();
	const displayAddCardModal = useToggle({initialState: false});
	const displayClaimVoucherModal = useToggle();
	const cheapestShippingMethod = minBy(shippingMethodsQuery.data?.data['hydra:member'], (sm: ShippingMethodCollectionModel) => sm.cost?.amount);
	const displayAddCouponModal = useToggle();
	const displayAddPhoneNumberModal = useToggle({initialState: false});
	const allowOnboardingOrderSetup = useToggle({initialState: true});

	useEffect(() => {
		if (order && myCardsQuery.data?.data && !paymentMethod) {
			// initialize payment method with order payment method
			setPaymentMethod(order?.payment?.paymentMethodId || myCardsQuery.data?.data.defaultPaymentMethod || undefined);
		}
	}, [myCardsQuery.data?.data, order]);

	useEffect(() => {
		if (!availableVouchers.data || appliedAvailableVoucher) return;
		const productsInCart = order?.orderItems?.map(oi => oi.product.id) || [];
		const voucherForOrderItem = availableVouchers.data.data['hydra:member']
			.find(v => productsInCart.includes(v.product.product?.id || ''));
		if (!voucherForOrderItem || applyVoucherMutation.isLoading) return;

		applyVoucherMutation.mutate({
			voucherCode: voucherForOrderItem.code,
		});
		setAppliedAvailableVoucher(true);
	}, [availableVouchers.data, order?.orderItems]);

	useEffect(() => {
		if (!allowOnboardingOrderSetup.state || !user || !user.defaultBillingAddress || !user.defaultShippingAddress || !cheapestShippingMethod || onboardingContext.onboardingStep === 'completed') return;
		onboardingOrderMutation.mutate({
			shippingAddress: user?.defaultShippingAddress?.['@id'],
			billingAddress: user?.defaultBillingAddress?.['@id'],
			shippingMethod: cheapestShippingMethod['@id'],
		});
	}, [order?.totalAmount, cheapestShippingMethod]);

	const checkoutHandler = async () => {
		if (!user || !order || !user.defaultBillingAddress || !user.defaultShippingAddress) return;

		if (!paymentMethod && (myCartQuery.data?.data.order.totalAmount.amount ?? 1) !== 0) {
			notifyError({message: 'Please select a payment method.'});
			return;
		}

		if (!cheapestShippingMethod) {
			notifyError({message: 'No shipping method found.'});
			return;
		}

		allowOnboardingOrderSetup.off();

		if (map.get('option') === 'new_dentist') {
			const isValid = await form.trigger();
			if (!isValid) {
				return;
			}
		}
		checkoutMutation.mutate({
			billingAddress: user.defaultBillingAddress['@id'],
			shippingAddress: user.defaultShippingAddress['@id'],
			cardId: (myCartQuery.data?.data.order.totalAmount.amount ?? 0) > 0 ? paymentMethod : undefined,
			shippingMethod: cheapestShippingMethod['@id'],
			paymentMethodType: 'card',
			scan: typeof map.get('scan') === 'string' ? (map.get('scan') as string | undefined) : undefined,
			dentistNotes:
				map.get('dentistNotes') && typeof map.get('dentistNotes') === 'object'
					? (map.get('dentistNotes') as DentistModel)
					: null,
			option: map.get('option') as CheckoutCompleteOption,
		});
	};


	// This should never have appeared, just for type safety
	if (!user || !order) return <>Loading...</>;
	if (!user?.defaultShippingAddress || !user?.defaultBillingAddress) {
		onboardingContext.setOnboardingStep('account');
		return <></>;
	}

	return <>
		<CartContentTable removeButton={false}/>
		<Button
			onClick={displayProductsListModal.on}
			variant='outline'
			size='md'
			className='w-fit mt-small'
			icon={<FontAwesomeIcon icon='cart-plus' className='mr-2'/>}>
			Add more products
		</Button>
		<AvailablePromotions/>
		{displayProductsListModal.state ? <Transition appear show={true} as={Fragment}>
			<Dialog as='div' className='relative z-10' onClose={displayProductsListModal.off}>
				<Transition.Child
					as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100'
					leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black bg-opacity-25'/>
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<Button onClick={displayProductsListModal.off} size='icon' className='absolute right-1 top-1 z-50'>
						<FontAwesomeIcon icon='times' className='h-5 w-5 z-50'/>
					</Button>
					<div className='flex h-full items-center justify-center md:p-3 lg:p-4 text-center'>
						<Transition.Child
							as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100' leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
							<Dialog.Panel
								className='w-full h-full overflow-y-scroll py-medium transform md:rounded-lg bg-background text-left align-middle shadow-xl transition-all'>
								<div className='container'>
									<ProductsList productsQuery={productsQuery}/>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition> : ''}

		<section className='grid md:grid-cols-4 gap-medium mx-auto w-full mt-medium'>
			<address className='col-span-4 md:col-span-2'>
				<h3 className='mb-small !text-muted/90'>Shipping Address</h3>
				<AddressFormatted className='!text-muted/40' address={user.defaultShippingAddress}/>
			</address>

			<address className='col-span-4 md:col-span-2'>
				<h3 className='mb-small !text-muted/90'>Billing Address</h3>
				<AddressFormatted className='!text-muted/40' address={user.defaultBillingAddress}/>
			</address>

			<div className='col-span-4 md:col-span-2 flex flex-col gap-small'>
				<h3 className='text-muted/90'>Payment</h3>

				{myCardsQuery.data?.data.paymentMethods.map(pm => <SmallPaymentMethodCard
					key={pm.id}
					paymentMethod={pm}
					onClick={() => setPaymentMethod(pm.id)}
					isSelected={paymentMethod === pm.id}/>)}

				{myCardsQuery.isFetching && !myCardsQuery.data ? <SmallPaymentMethodCardSkeleton/> : ''}

				{(myCartQuery.data?.data.order.totalAmount.amount ?? 1) === 0
					? <Alert className='mt-0'>
						<AlertTitle>
							No payment method required
						</AlertTitle>
						<AlertDescription>
							Payment method is not required as you used voucher / promo code and amount to pay
							is <MoneyFormatted money={{amount: 0, currency: 'USD'}}/>.
						</AlertDescription>
					</Alert>
					: <div className='flex flex-wrap gap-small justify-between'>
						<Button className='w-fit' size='md' variant='outline' onClick={displayAddCardModal.on}>
							<FontAwesomeIcon icon='credit-card'/>
							add card
						</Button>
						<Button className='w-fit' size='md' variant='default' onClick={displayClaimVoucherModal.on}>
							Claim voucher
						</Button>

						<div className='w-full'>
							<Button
								onClick={displayAddCouponModal.on}
								size='lg'
								className='w-full sm:w-auto !pl-0'
								variant='link'>
								Add promo code
							</Button>
							<AppliedCoupons coupons={order.coupons}/>
						</div>
						<Modal
							title='Add Promocode'
							isOpen={displayAddCouponModal.state}
							onClose={displayAddCouponModal.off}>
							<div className='grid gap-medium'>
								<RedeemCouponForm onSuccess={displayAddCouponModal.off}/>
							</div>
						</Modal>
					</div>}

			</div>
			<div className='col-span-4 md:col-span-2 flex flex-col max-w-full'>
				<h3 className='mb-small text-muted/90'>Summary</h3>
				{myCartQuery.isFetching || onboardingOrderMutation.isLoading ? <OrderSummaryLoadingSkeleton/> :
					<OrderAmountSummaryCard
						order={order}
						className='border-2 md:border-none p-small md:p-0 rounded [&>p.total]:text-3xl [&>p.total]:font-semibold [&>p.total]:text-primary [&>p.total]:uppercase'
						cardProps={{variant: 'ghost'}}
					/>}
			</div>
		</section>

		<section className='mt-medium'>
			<FormProvider {...form}>
				<OrderScanOption
					changeHandler={(k, v) => actions.set(k as keyof CartCheckoutCompleteRequest, v)}
					order={order}
					option={map.get('option') as CheckoutCompleteOption}/>
			</FormProvider>
		</section>
		<div className='flex flex-col-reverse md:flex-row flex-wrap justify-between items-center mt-medium'>
			{checkoutMutation.isError ? <Alert variant='destructive' className='w-full'>
				<AlertTitle>{checkoutMutation.error.response?.data['hydra:title'] || 'Error'}</AlertTitle>
				<AlertDescription>{checkoutMutation.error.response?.data['hydra:description'] || 'Unknown error, please check your payment.'}</AlertDescription>
			</Alert> : ''}
			<Button type='button' variant='link' className='w-fit text-muted/80 md:pl-0 cursor-pointer' asChild>
				<Button onClick={() => onboardingContext.setOnboardingStep('account')} variant='link' size='sm'>
					<FontAwesomeIcon icon='chevron-left' className='mr-2'/>
					Account
				</Button>
			</Button>

			<Button
				onClick={() => (!user?.phone || !user?.contactConsentSettings?.transactionalCalls) ? displayAddPhoneNumberModal.on() : checkoutHandler()}
				state={checkoutMutation.status}
				disabled={order.orderItems.length === 0 || myCartQuery.isFetching || onboardingOrderMutation.isLoading}
				size='lg'
				className='w-full md:w-fit ml-auto'
				icon={<FontAwesomeIcon icon='cash-register' className='mr-2'/>}>
				Complete order
			</Button>
		</div>

		<Modal onClose={displayAddCardModal.off} isOpen={displayAddCardModal.state} title='Add new card'>
			<AddStripeCardForm onSuccess={(pm) => {
				displayAddCardModal.off();
				if (typeof pm === 'string') {
					setPaymentMethod(pm);
				} else {
					pm && setPaymentMethod(pm.id);
				}
			}}/>
		</Modal>

		<Modal
			title='Claim voucher'
			isOpen={displayClaimVoucherModal.state}
			onClose={displayClaimVoucherModal.off}>
			<p>
				You can claim a voucher code to get a discount on your order.
			</p>
			<ClaimVoucherForm onSuccess={displayClaimVoucherModal.off}/>
		</Modal>

		<Modal
			dialogPanelClassName='max-w-2xl'
			onClose={displayAddPhoneNumberModal.off}
			isOpen={displayAddPhoneNumberModal.state}
			title='ENTER YOUR PHONE NUMBER'>
			<AddPhoneForm onSuccess={() => {
				displayAddPhoneNumberModal.off();
				checkoutHandler();
			}}/>
		</Modal>
	</>;
}