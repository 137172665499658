import {HydraModel} from '@mgp-fe/shared/core-api/domain/base.ts';
import {Customer} from '@mgp-fe/shared/core-api/domain/user.ts';
import {DentistModel} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';

export interface ScanCollectionModel extends HydraModel {
	provider?: ScanProvider;
	state: ScanState;
	dateOfScan?: Date;
	dentistNotes: DentistModel;
	customer: Customer;
}

export type ScanModel = ScanCollectionModel;

export type ScanProvider = typeof scanProviders[number];

export const scanProviders = [
	'itero',
	'3shape',
	'medit',
	'carestream',
	'cerex',
	'icat',
	'itero & trios',
	'panorama',
	'planmeca',
	'scan x',
	'sirona',
	'sprintray',
	'trios',
	'vortex',
	'impression_kit',
	'external',
] as const;

export type ScanState = typeof scanState[number];

export const scanState = [
	'requested',
	'waiting',
	'synced',
	'outdated',
	'cancelled',
] as const;
export const ImpressionKitStateMap: Record<ScanState, string> = {
	'requested': 'waiting_for_send',
	'waiting': 'sent',
	'synced': 'received',
	'outdated': 'not_fit',
	'cancelled': 'cancelled',
};
