import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import keysResolver from '@mgp-fe/shared/core-api/keysResolver.ts';
import endpoints from '@mgp-fe/shared/core-api/endpoints.ts';
import coreApiClient from '@mgp-fe/shared/core-api/client.ts';
import {InvitationModel} from '@mgp-fe/shared/core-api/domain/invitation.ts';
import {HydraError} from '@mgp-fe/shared/core-api/domain/base.ts';

export default function useInvitationDetailQuery(props: InvitationDetailQueryProps) {
	return useQuery<AxiosResponse<InvitationModel>, AxiosError<HydraError>>({
		queryKey: keysResolver(endpoints.invitations.list, 'get', props.invitationId),
		queryFn: () => coreApiClient.get(endpoints.invitations.detail(props.invitationId)),
		...props?.options,
		// select: (data: InvitationModel) => data.data,
	});
}

interface InvitationDetailQueryProps {
	invitationId: string;
	options?: UseQueryOptions<AxiosResponse<InvitationModel>, AxiosError<HydraError>>;
}