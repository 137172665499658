import React from 'react';
import {InputProps} from '@mgp-fe/shared/ui/form/TextInput.tsx';
import PhoneInputWithCountry, {ReactHookFormComponentProps} from 'react-phone-number-input/react-hook-form';
import 'react-phone-number-input/style.css';
import './PhoneNumberInput.module.scss';
import {FieldValues, useFormContext, useFormState} from 'react-hook-form';
import {isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input';
import getErrorMessage from '@mgp-fe/shared/ui/form/error-message.ts';
import {cn} from '@mgp-fe/shared/utils';

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
	const form = useFormContext();
	const {errors} = useFormState({
		control: form.control,
	});

	const phoneNumber = form.watch(props.name);

	const errorMessage = getErrorMessage(errors, props.name);

	return <label className={cn(
		'flex flex-col gap-mini mt-small relative w-full',
		props.labelClassName || '')}>
		{props.label ? <span>{props.label}</span> : ''}

		<div className='w-full'>
			<PhoneInputWithCountry
				name={props.name}
				placeholder='Enter phone number'
				defaultCountry={'US'}
				control={form.control as ReactHookFormComponentProps<FieldValues>['control']}
			/>
			{props.errorMessageUnderInput && (props.name && errorMessage) ? <p className='text-red-600 text-sm'>
				{errorMessage}
			</p> : ''}
		</div>

		{!props.errorMessageUnderInput && (props.name && errorMessage) ? <span
			className='absolute -top-2 right-0 w-3/4 bg-destructive/80 text-sm text-destructive-foreground px-2 py-1 rounded-md'>
			{errorMessage as string}
		</span> : ''}
		{phoneNumber && !isValidPhoneNumber(phoneNumber) && isPossiblePhoneNumber(phoneNumber) ? <span className='absolute top-0 right-0 w-fit bg-warning/80 text-xs text-destructive-foreground px-2 py-1 rounded-md'>
			Number could be incorrect.
		</span> : ''}
	</label>;
}

type PhoneNumberInputProps = InputProps & {
	errorMessageUnderInput?: boolean,
};