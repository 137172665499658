import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {
	DentistModel,
	DentistNoteRequestSchema,
} from '@mgp-fe/shared/core-api/mutations/cart/checkout-complete.ts';
import {z} from 'zod';
import PhoneNumberInput from '@mgp-fe/shared/ui/form/PhoneNumberInput.tsx';

export default function DentistNoteForm({onChange}: DentistNoteFormProps) {

	const {
		register,
		watch,
		formState: {errors},
	} = useFormContext<z.infer<typeof DentistNoteRequestSchema>>();
	const watchedValues = watch();

	useEffect(() => {
		onChange(watchedValues as DentistModel);
	}, [watchedValues, onChange]);

	return <div
		className='flex flex-col border border-secondary-500/20 rounded-md pb-5 p-2.5 items-center justify-center horizontal-labels gap-5 w-full '>
		<h3>Dentist information</h3>
		<div className='grid md:items-center w-full md:justify-center md:grid-cols-2 gap-small '>
			<label className='flex flex-col gap-mini  mt-small  '>
				<span>Office name</span>
				<input type='text' required className='w-full' {...register('officeName')}
				/>
				{errors.officeName && (
					<p className='text-red-600 text-sm'>{errors.officeName.message}</p>
				)}
			</label>
			<label className='flex flex-col gap-mini  mt-small  '>
				<span>Dentist name</span>
				<input type='text' required className='w-full' {...register('dentistName')} />
				{errors.dentistName && (
					<p className='text-red-600 text-sm'>{errors.dentistName.message}</p>
				)}
			</label>
		</div>
		<div className='grid md:items-center w-full md:justify-center md:grid-cols-2 gap-small '>
			<PhoneNumberInput label='Phone number' name='phone' errorMessageUnderInput={true}/>
			<label className='flex flex-col gap-mini mt-small'>
				<span>Email</span>
				<input type='email' className='w-full' formNoValidate={true}  {...register('email')} />
			</label>
		</div>
	</div>
	;
}

interface DentistNoteFormProps {
	onChange: (values: DentistModel) => void; // <-- simple callback
}